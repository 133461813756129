import React from 'react';
import WebsiteDefaultPage from '../components/Page/Base';
import { LIGHT_DARK } from '../constants';
import { Color } from '../styles';
import { ComingSoon, Hero, Links } from '../components/PageSpecific/community';

const Community = () => {
  return (
    <WebsiteDefaultPage bodyBackground={Color.DARK_GRAY} pageState={{ theme: LIGHT_DARK.DARK }}>
      <Hero />
      <Links />
    </WebsiteDefaultPage>
  );
};

export default Community;
