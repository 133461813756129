import React from 'react';
import styled, { css } from 'styled-components';
import {
  Color,
  fullColorBackground,
  grid,
  gridColumns,
  h3Desktop,
  hover,
  marginBottom,
  marginTop,
  maxWidth,
  padding,
  width,
  Width,
} from '../../../styles';
import { Link } from 'gatsby';

const Wrapper = styled.section`
  ${maxWidth('32.7rem', '45.4rem', '120rem')};
  ${marginTop(16, 0, 32)};
  ${marginBottom(40, 60, 160)};
  margin-inline: auto;
  ${grid()};
  ${gridColumns(1, 1, 3)};
  ${padding('64px 0', '40px 0', '80px 118px')};
  ${fullColorBackground(Color.BLACK)};
`;

const LinkBlock = styled.div<{ border?: boolean }>`
  ${padding('40px 0', '40px 0', '48px 64px')};
  ${width('100%', '100%', '39.2rem')};

  p {
    color: ${Color.WHITE};
  }

  a {
    display: block;
    ${h3Desktop};
    color: ${Color.GREEN};
    ${marginTop(8, 8, 24)};
    ${hover('text-decoration', 'none', 'underline')};
  }

  ${({ border }) =>
    border &&
    css`
      border-top: 1px solid ${Color.WHITE};
      border-bottom: 1px solid ${Color.WHITE};

      @media print, screen and (min-width: ${Width.BREAK_XL}px) {
        border-top: none;
        border-bottom: none;
        border-left: 1px solid ${Color.WHITE};
        border-right: 1px solid ${Color.WHITE};
      }
    `}
`;

const Links = () => (
  <Wrapper>
    <LinkBlock>
      <p>Blogs, videos, and resources</p>
      <a href={'/community/blogs'} target="_blank" rel="noopener noreferrer">
        Learn
      </a>
    </LinkBlock>
    <LinkBlock border>
      <p>Join us in-person or virtually</p>
      <Link to={'/community/events'} >Events</Link>
    </LinkBlock>
    <LinkBlock>
      <p>Press announcements</p>
      <Link to={'/community/news'}>In the News</Link>
    </LinkBlock>
  </Wrapper>
);

export default Links;
