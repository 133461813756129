import React from 'react';
import styled from 'styled-components';
import {
  bodyBold,
  Color,
  displayDesktop,
  displayNoneByBreakpoint,
  flex,
  flexGap,
  h2Desktop,
  h2Mobile,
  marginLeft,
  marginRight,
  marginTop,
  maxWidth,
  typography,
  typographyByBreakpoint,
  Width,
  width,
  xxlDesktop,
  xxlMobile,
} from '../../../styles';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from '../../Gui/Button';

const Wrapper = styled.section`
  ${maxWidth('32.7rem', '68.8rem', '120rem')};
  ${marginTop(136, 152, 206)};
  margin-inline: auto;
`;

const Content = styled.div`
  h1,
  h2,
  p {
    color: ${Color.WHITE};
  }

  h1 {
    ${typographyByBreakpoint(typography('4.8rem', 'calc(40 / 61)', '0.1rem', 400), xxlMobile, xxlDesktop)};
    color: ${Color.SEAFOAM_GREEN};

    @media print, screen and (min-width: ${Width.BREAK_XL}px) {
      margin-bottom: 7rem;
    }
  }

  > div {
    ${flex('flex-start', 'flex-start', 'column')};
    ${flexGap(40, 40, 93)};

    @media print, screen and (min-width: ${Width.BREAK_MD}px) {
      flex-direction: row-reverse;
    }

    @media print, screen and (min-width: ${Width.BREAK_XL}px) {
      flex-direction: row;
    }
  }

  .gatsby-image-wrapper {
    ${width('45.5rem', '45.5rem', '85.3rem')};
    ${marginTop(40, 40, -68)};
    ${marginLeft(-144, -139, 0)};
    ${marginRight(0, 0, -216)};
    flex: 0 0 auto;
  }
`;

const Blurb = styled.div`
  ${maxWidth('100%', '32.7rem', '46.8rem')};
  ${marginTop(32, 88, 72)};
  ${marginLeft(0, 0, 142)};
  flex: 0 0 auto;

  h2 {
    ${typographyByBreakpoint(h2Mobile, h2Mobile, h2Desktop)};

    span {
      color: ${Color.GREEN};
    }
  }

  .bold {
    ${bodyBold};
    ${marginTop(24, 24, 40)};
  }

  .regular {
    margin-top: 16px;
  }

  .button-wrapper {
    display: inline-block;
    margin-top: 32px;
    height: 5.1rem;
    line-height: 1.5;
  }
`;

const Community = () => (
  <Wrapper>
    <Content>
      <h1>Ready Neighborhood</h1>
      <div>
        <Blurb>
          <h2>
            Interested in <br />
            <span>
              partnering with <br />
              Ready Life?
            </span>
          </h2>
          <p className={'bold'}>
            Ready Life is a mission-driven, for-profit company that is very community oriented and promotes community engagement.{' '}
          </p>
          <p className={'regular'}>Please fill out this form to contact Ready Life and explore community partnerships.</p>
          <Button colorTheme={'DARK'} url={'/partnerships'}>
            Contact us
          </Button>
        </Blurb>
        <StaticImage src={'../../../images/community/community-image.png'} alt={''} placeholder={'none'} />
      </div>
    </Content>
  </Wrapper>
);

export default Community;
