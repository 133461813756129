import React from 'react';
import styled from 'styled-components';
import {
  Color,
  columnSpacing,
  grid,
  gridColumnPosition,
  gridColumns,
  h2Desktop,
  h2Mobile,
  h3Desktop,
  marginTopBottom,
  maxWidth,
  rowSpacing,
  typographyByBreakpoint,
} from '../../../styles';

const Wrapper = styled.section`
  ${maxWidth('32.7rem', '68.8rem', '87.2rem')};
  margin-left: auto;
  margin-right: auto;
  ${marginTopBottom([18, 64], [80, 80], [-100, 100])};

  ${grid()};
  ${gridColumns(1, 2, 2)};
  ${rowSpacing(24, 48, 72)};
  ${columnSpacing(0, 48, 64)};
  justify-items: start;

  h2 {
    ${typographyByBreakpoint(h2Mobile, h2Mobile, h2Desktop)};
    color: ${Color.WHITE};
    ${gridColumnPosition('1 / 2', '1 / 3', '1 / 3')};
  }
`;

// p styling is currently unused - Waiting on text for each Block
const Block = styled.div`
  h3 {
    ${h3Desktop};
    color: ${Color.WHITE};
    margin-bottom: 16px;
  }

  p {
    color: ${Color.WHITE};
  }
`;

const ComingSoon = () => (
  <Wrapper>
    <h2>Coming soon</h2>
    <Block>
      <h3>Ready Life Homeownership Program</h3>
    </Block>  
    <Block>
      <h3>Ready Partnerships</h3>
    </Block>
    <Block>
      <h3>Ready Sports & Entertainment</h3>
    </Block>
  </Wrapper>
);

export default ComingSoon;
